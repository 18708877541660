import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
            <h1>Messina Messenger</h1>

<h2>Men seen looking in windows, citizens urged to take precautions</h2>

<p>
Citizens of Messina are advised to shut their blinds tightly after several unknown men were spotted peering into windows of private residences late last night. The incidents occurred between midnight and 1:00 a.m.
At a press conference that he called this morning, Dogberry, local private eye and security guard, expressed concern. “All kinds of people seen them peeping in windows,” he said. 

Dogberry advises all residents to continue to be “extra vigilante.” While vigilance is prudent, the Messina Messenger would like to state that we do not support vigilantism.

</p>
            </div>
        </Layout>
    )
}